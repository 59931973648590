import { parseValidDateOrDefault } from '@/common/helpers'

import {
  ListShortStudent,
  ListShortStudentApiResult,
  ListTeacherApiResult,
  ListUserApiResult,
  ShortUser,
  Teacher
} from './contracts'

export function fromUserApiSanitizer(profiles: ListUserApiResult['data']): ShortUser[] {
  return profiles.map((profile) => ({
    ...profile,
    allowedImageUseOnEdtech: profile.allowed_image_use_on_edtech,
    birthday: parseValidDateOrDefault(profile.birthday),
    organization: {
      id: profile.institution_id,
      code: profile.institution_code,
      name: profile.institution_name,
      address: profile.institution_address
    },
    role: {
      id: profile.role_id,
      code: profile.role_code,
      name: profile.role_name
    },
    userId: profile.user_id
  }))
}

export function listShortStudentApiSanitizer(
  students: ListShortStudentApiResult['data']
): ListShortStudent[] {
  return students.map((student) => ({
    ...student,
    allowedImageUseOnEdtech: student.allowed_image_use_on_edtech,
    classrooms: student.classrooms.map((classroom) => ({
      ...classroom,
      enrollmentDate: classroom.enrollment_date
    }))
  }))
}

export function listTeacherApiSanitizer(teachers: ListTeacherApiResult['data']): Teacher[] {
  return teachers.map((teacher) => ({
    ...teacher,
    lastEnrollmentDate: teacher.last_enrollment_date,
    courses: teacher.courses.map((course) => ({
      ...course
    }))
  }))
}
