import { i18n } from '@/common/i18n'
import { listHubApiResultSanitizer } from '@/common/sanitizers'
import { hubApiService } from '@/common/services'

import {
  ListShortStudentApiParams,
  ListShortStudentApiResult,
  ListShortStudentServiceParams,
  ListShortStudentServiceResult,
  ListTeacherApiParams,
  ListTeacherApiResult,
  ListTeacherServiceParams,
  ListTeacherServiceResult,
  ListUserApiParams,
  ListUserApiResult,
  ListUserServiceParams,
  ListUserServiceResult,
  UpdateStudentConsentApiParams,
  UpdateStudentConsentServiceParams
} from './contracts'
import {
  fromUserApiSanitizer,
  listShortStudentApiSanitizer,
  listTeacherApiSanitizer
} from './sanitizers'

export async function listUserService(
  params: ListUserServiceParams
): Promise<ListUserServiceResult> {
  const {
    data: { data, account, ...result }
  } = await hubApiService<ListUserApiParams, ListUserApiResult>({
    resource: i18n().modules.hub.profiles.resources.list,
    method: 'get',
    url: '/users/v1/users',
    params: {
      org_id: params.orgId,
      app_id: params.appId,
      search: params.search,
      role_code: params.roleCode,
      page: params.page,
      size: params.perPage
    }
  })

  return {
    ...listHubApiResultSanitizer({ ...result, data: fromUserApiSanitizer(data) }),
    account
  }
}

export async function listShortStudent({
  classroomId,
  ignoreClassroomId,
  search,
  perPage,
  page
}: ListShortStudentServiceParams): Promise<ListShortStudentServiceResult> {
  const { data } = await hubApiService<ListShortStudentApiParams, ListShortStudentApiResult>({
    resource: i18n().modules.hub.classroom.resources.shortStudent,
    method: 'get',
    url: '/users/v1/students',
    params: {
      offset: page,
      limit: perPage,
      classroom_id: classroomId,
      ignore_classroom_id: ignoreClassroomId,
      search
    }
  })

  return {
    ...listHubApiResultSanitizer({
      ...data,
      total: data.total_count,
      total_pages: data.pages,
      content_range: data.per_page,
      data: listShortStudentApiSanitizer(data.data)
    })
  }
}

export async function updateStudentConsentService(
  params: UpdateStudentConsentServiceParams
): Promise<void> {
  await hubApiService<UpdateStudentConsentApiParams, undefined>({
    resource: i18n().modules.hub.profiles.resources.updateConsent,
    method: 'put',
    url: `/users/v1/profiles/consent/${params.studentId}`,
    body: {
      consent: params.consent
    }
  })
}

export async function listTeacher({
  classroomId,
  ignoreClassroomId,
  search,
  perPage
}: ListTeacherServiceParams): Promise<ListTeacherServiceResult> {
  const { data } = await hubApiService<ListTeacherApiParams, ListTeacherApiResult>({
    resource: i18n().modules.hub.disciplines.resources.availableDiscipline,
    method: 'get',
    url: '/users/v1/teachers',
    params: {
      limit: perPage,
      classroom_id: classroomId,
      ignore_classroom_id: ignoreClassroomId,
      search
    }
  })

  return {
    ...listHubApiResultSanitizer({
      ...data,
      total: data.total_count,
      total_pages: data.pages,
      content_range: data.per_page,
      data: listTeacherApiSanitizer(data.data)
    })
  }
}
