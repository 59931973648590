import { Badges } from '@positivote/design-system/components/Badges'
import { BaseCardButton } from '@positivote/design-system/components/BaseCardButton'
import { Button } from '@positivote/design-system/components/Button'
import { Checkbox } from '@positivote/design-system/components/Checkbox'
import { Div } from '@positivote/design-system/components/Div'
import { Grid } from '@positivote/design-system/components/Grid'
import { IconButton } from '@positivote/design-system/components/IconButton'
import { LinkButton } from '@positivote/design-system/components/LinkButton'
import { Loader } from '@positivote/design-system/components/Loader'
import { Main } from '@positivote/design-system/components/Main'
import { Pagination } from '@positivote/design-system/components/Pagination'
import { Select } from '@positivote/design-system/components/Select'
import { Span } from '@positivote/design-system/components/Span'
import { TextField } from '@positivote/design-system/components/TextField'
import { Typography } from '@positivote/design-system/components/Typography'
import { UL } from '@positivote/design-system/components/UL'
import { useTheme } from '@positivote/design-system/hooks'
import { AddCircleIcon } from '@positivote/design-system/icons/AddCircle'
import { CancelIcon } from '@positivote/design-system/icons/Cancel'
import { DeleteIcon } from '@positivote/design-system/icons/Delete'
import { EditIcon } from '@positivote/design-system/icons/Edit'
import { FilterListIcon } from '@positivote/design-system/icons/FilterList'
import { SearchIcon } from '@positivote/design-system/icons/Search'
import { Breakpoint } from '@positivote/design-system/theme'
import { useEffect, useMemo, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { AppBar } from '@/common/components/AppBar'
import { EmptyList } from '@/common/components/EmptyList'
import { EmptySearch } from '@/common/components/EmptySearch'
import { TextDialog } from '@/common/components/TextDialog'
import {
  DEFAULT_BREAK_POINT_PER_PAGE,
  XL_BREAK_POINT_PER_PAGE
} from '@/common/constants/react-query'
import { debounceEvent } from '@/common/helpers'
import { i18n } from '@/common/i18n'
import { PersonAddIcon } from '@/fixme/icons/PersonAdd'
import { ListClassroomHookParams } from '@/modules/hub/classroom/contracts'
import {
  useListClassLevel,
  useListClassroom,
  useRemoveClassroom
} from '@/modules/hub/classroom/hooks'
import { SchoolYearFormatted } from '@/modules/hub/school-year/contracts'
import { useListSchoolYear } from '@/modules/hub/school-year/hooks'

interface SelectOption {
  id: number
  icon: JSX.Element
  title: string
}

export function ListClassroom(): JSX.Element {
  const navigate = useNavigate()
  const { breakpoint } = useTheme()
  const removeClassroom = useRemoveClassroom()
  const [showFilter, setShowFilter] = useState(false)
  const [classroomsIdsToRemove, setClassroomsIdsToRemove] = useState<number[]>([])
  const [classroomsIdToRemove, setClassroomsIdToRemove] = useState<number[]>([])
  const [selectedSchoolYear, setSelectedSchoolYear] = useState<SchoolYearFormatted | null>(null)
  const [selectedClassLevel, setSelectedClassLevel] = useState(0)
  const location = useLocation()
  const locationState = location.state as {
    schoolYear: { name: string; id: number }
  } | null

  const [removeClassroomDialog, setRemoveClassroomDialog] = useState<{
    title: string
    id: number
    isBulk: boolean
  } | null>(null)

  const [listClassroomParams, setListClassroomParams] = useState<ListClassroomHookParams['model']>({
    page: 1,
    perPage: breakpoint === Breakpoint.xl ? XL_BREAK_POINT_PER_PAGE : DEFAULT_BREAK_POINT_PER_PAGE
  })

  const listSchoolYear = useListSchoolYear({
    model: {
      perPage: 100
    }
  })

  const listClassroom = useListClassroom({
    model: {
      ...listClassroomParams,
      schoolYearId: selectedSchoolYear?.id,
      levelId: selectedClassLevel === 0 ? undefined : listClassroomParams.levelId
    },
    queryOptions: {
      enabled: !!selectedSchoolYear
    }
  })

  const listClassLevel = useListClassLevel({
    model: {
      termCode: selectedSchoolYear?.code,
      schoolYearId: selectedSchoolYear?.id
    },
    queryOptions: {
      enabled: !!selectedSchoolYear
    }
  })

  const listClassLevelFormatted = [
    { id: 0, name: i18n().modules.hub.classroom.pages.list.allYears },
    ...(listClassLevel.data?.registers ?? [])
  ]

  const isLoading = listSchoolYear.isPending || listClassroom.isPending

  const filteredSchoolYearOptions = useMemo<SelectOption[]>(() => {
    return (
      listSchoolYear.data?.registers.map((year) => ({
        id: year.id,
        icon: <Badges fill={year.status === 'active' ? '$success' : '$neutral-70'} />,
        title: year.title
      })) ?? []
    )
  }, [listSchoolYear.data?.registers])

  function handleChangeSearchText(event: React.ChangeEvent<HTMLInputElement>): void {
    const search = event.target.value || undefined
    debounceEvent(() => {
      setListClassroomParams((oldState) => ({ ...oldState, search, page: 1 }))
    })()
  }

  const isAllClassroomsSelected = useMemo(() => {
    const classroomsIds = listClassroom.data?.registers.map((classroom) => classroom.id) ?? []

    return classroomsIds.every((id) => classroomsIdsToRemove.includes(id))
  }, [listClassroom.data?.registers, classroomsIdsToRemove])

  function handleSelectAllClassrooms(): void {
    const classroomsIdsIds = listClassroom.data?.registers.map((classroom) => classroom.id) ?? []

    const allSelected = classroomsIdsIds.every((id) => classroomsIdsToRemove.includes(id))

    if (allSelected) {
      setClassroomsIdsToRemove((prevSelected) =>
        prevSelected.filter((id) => !classroomsIdsIds.includes(id))
      )
    } else {
      setClassroomsIdsToRemove((prevSelected) => [...prevSelected, ...classroomsIdsIds])
    }
  }

  function handleSelectClassrooms(id: number): void {
    setClassroomsIdsToRemove((old) => {
      if (old.includes(id)) {
        return old.filter((classroomId) => classroomId !== id)
      } else {
        return [...old, id]
      }
    })
  }

  function handleRemoveClassroom(): void {
    removeClassroom.mutate({
      model: {
        ids: classroomsIdToRemove.length ? classroomsIdToRemove : classroomsIdsToRemove
      },
      page: listClassroomParams.page!,
      perPage: listClassroomParams.perPage!,
      onSuccess: () => {
        if (listClassroom.data?.registers.length === 1) {
          setShowFilter(false)
          setListClassroomParams((oldData) => ({
            ...oldData,
            page: (oldData.page ?? 1) - 1 || 1
          }))
        }
        if (classroomsIdToRemove.length) {
          setClassroomsIdToRemove([])
          setClassroomsIdsToRemove((prevSelected) =>
            prevSelected.filter((classroomId) => classroomId !== classroomsIdToRemove[0])
          )
        } else {
          setClassroomsIdsToRemove([])
        }

        setRemoveClassroomDialog(null)
      }
    })
  }

  function handleSelectSchoolYear(id: number): void {
    setSelectedSchoolYear(
      listSchoolYear.data?.registers.find((schoolYear) => schoolYear.id === id) ?? null
    )
  }

  useEffect(() => {
    setSelectedSchoolYear(listSchoolYear.data?.registers[0] ?? null)
  }, [listSchoolYear.data?.registers])

  useEffect(() => {
    if (listClassroom.data?.registers.length && !showFilter) {
      setShowFilter(true)
    }
  }, [listClassroom.data?.registers.length, showFilter])

  useEffect(() => {
    if (locationState?.schoolYear.id) {
      const schoolYearSelected = listSchoolYear.data?.registers.find(
        (schoolYear) => schoolYear.id === locationState.schoolYear.id
      )
      setSelectedSchoolYear(schoolYearSelected ?? null)
      navigate(window.location.pathname, { state: null })
    }
  }, [listSchoolYear.data?.registers, locationState?.schoolYear.id, navigate])

  return (
    <Main css={{ display: 'flex', flexDirection: 'column', flex: 1, overflowX: 'hidden' }}>
      <TextDialog
        isOpen={!!removeClassroomDialog?.id || !!removeClassroomDialog?.isBulk}
        title={{
          label: i18n().modules.hub.classroom.pages.list.removeDialog.title(
            removeClassroomDialog?.isBulk ?? false
          )
        }}
        contentTexts={
          removeClassroomDialog?.isBulk
            ? [i18n().modules.hub.classroom.pages.list.removeDialog.bulkContentTextMain]
            : [
                <>
                  <Typography variant="bodyLarge" css={{ color: '$on-surface-variant' }}>
                    {i18n().modules.hub.classroom.pages.list.removeDialog.contentTextMain}
                    <Span css={{ fontWeight: 'bold' }}>
                      {i18n().modules.hub.classroom.pages.list.removeDialog.selectedClassroom(
                        removeClassroomDialog?.title
                      )}
                    </Span>
                  </Typography>
                </>
              ]
        }
        onCancel={() => setRemoveClassroomDialog(null)}
        refuseAction={{
          icon: <CancelIcon size={18} />,
          label: i18n().modules.billing.serviceMapping.pages.list.removeDialog.refuse,
          handle: () => setRemoveClassroomDialog(null)
        }}
        acceptAction={{
          icon: <DeleteIcon size={18} />,
          label: i18n().modules.hub.classroom.pages.list.removeDialog.accept,
          handle: () => handleRemoveClassroom()
        }}
        isLoading={removeClassroom.isPending}
        css={{
          maxWidth: 600,
          '& .Dialog-Content': {
            gap: '$none'
          }
        }}
      />
      <AppBar
        title={i18n().modules.hub.classroom.pages.list.appBar.title}
        goBackFunction={() => navigate(-1)}
        trailingElement={
          <>
            <Select
              leadingElement={
                listSchoolYear.isFetching ? undefined : (
                  <Badges
                    fill={selectedSchoolYear?.status === 'active' ? '$success' : '$neutral-70'}
                  />
                )
              }
              label={i18n().modules.hub.classroom.pages.list.schoolYear}
              leadingElementField="icon"
              data-testid="classLevel"
              optionKeyField="id"
              optionTitleField="title"
              options={filteredSchoolYearOptions}
              variant="outlined"
              value={selectedSchoolYear?.id}
              onChange={(options) => {
                setSelectedClassLevel(0)
                handleSelectSchoolYear(options!.id)
              }}
            />
            {!!listClassroom.data?.registers.length && (
              <Button
                disabled={!selectedSchoolYear}
                onClick={() =>
                  navigate('/data-management/levels-and-classes/classroom/form', {
                    state: {
                      schoolYear: { name: selectedSchoolYear?.title, id: selectedSchoolYear?.id }
                    }
                  })
                }
                variant="tonal"
                LeadingIcon={<AddCircleIcon size={18} />}
                css={{ minWidth: 206 }}
              >
                {i18n().modules.hub.classroom.pages.list.addNewClass}
              </Button>
            )}
          </>
        }
        breadcrumbItems={[
          {
            label: i18n().modules.hub.classroom.pages.list.appBar.breadcrumbs.overview,
            onClick: () => {
              navigate(-2)
            }
          },
          {
            label:
              i18n().modules.hub.classroom.pages.list.appBar.breadcrumbs.educationLevelsAndClasses,
            onClick: () => {
              navigate(-1)
            }
          },
          {
            label: i18n().modules.hub.classroom.pages.list.appBar.breadcrumbs.schoolClasses
          }
        ]}
      />

      <Div
        css={{
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
          padding: '$lg',
          overflowY: 'auto',
          '@sm': { padding: '$md' }
        }}
      >
        {showFilter && (
          <>
            <Div css={{ display: 'flex', alignItems: 'center', gap: '$2xs', marginBottom: '$md' }}>
              <FilterListIcon size={18} />
              <Typography
                data-testid="Typography-titleFilter"
                variant="titleMedium"
                css={{ color: '$on-surface' }}
              >
                {i18n().modules.hub.classroom.pages.list.filter.title}
              </Typography>
            </Div>
            <Grid spacing="$lg">
              <Grid xl={3}>
                <Select
                  label={i18n().modules.hub.classroom.pages.list.filter.schoolYear}
                  data-testid="classLevel"
                  optionKeyField="id"
                  optionTitleField="name"
                  value={selectedClassLevel}
                  options={listClassLevelFormatted}
                  variant="outlined"
                  onChange={(level) => {
                    setSelectedClassLevel(level!.id)
                    setListClassroomParams((oldState) => ({
                      ...oldState,
                      levelId: level!.id,
                      page: 1
                    }))
                  }}
                />
              </Grid>
              <Grid xl={9}>
                <TextField
                  label={i18n().modules.hub.classroom.pages.list.filter.search}
                  variant="outlined"
                  data-testid="search"
                  leadingIcon={{ icon: SearchIcon }}
                  inputProps={{
                    onChange: handleChangeSearchText
                  }}
                />
              </Grid>
            </Grid>
          </>
        )}
        {!listClassroom.data?.registers.length ? (
          <Div
            css={{
              display: 'flex',
              flexDirection: 'column',
              padding: '$lg',
              flex: 1,
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            {isLoading && <Loader data-testid="Loader-Container-ServiceMappingList" size={80} />}
            {!isLoading && !listClassroomParams.search && (
              <Div
                css={{ display: 'flex', flexDirection: 'column', gap: '$md', alignItems: 'center' }}
              >
                <EmptyList title={i18n().modules.hub.classroom.pages.list.emptyList} />
                <Button
                  disabled={!selectedSchoolYear}
                  onClick={() =>
                    navigate('/data-management/levels-and-classes/classroom/form', {
                      state: {
                        schoolYear: { name: selectedSchoolYear?.title, id: selectedSchoolYear?.id }
                      }
                    })
                  }
                  variant="tonal"
                  LeadingIcon={<AddCircleIcon size={18} />}
                  css={{ minWidth: 206 }}
                >
                  {i18n().modules.hub.classroom.pages.list.addNewClass}
                </Button>
              </Div>
            )}
            {!isLoading && listClassroomParams.search && <EmptySearch />}
          </Div>
        ) : (
          <Div
            css={{
              display: 'flex',
              flexDirection: 'column',
              flex: 1
            }}
          >
            <Grid spacing="$md" css={{ padding: '$md $lg' }}>
              <Grid xl={3} css={{ display: 'flex', alignItems: 'center', gap: '$md' }}>
                <Checkbox
                  inputProps={{ checked: isAllClassroomsSelected }}
                  onClick={handleSelectAllClassrooms}
                />
                <Typography variant="titleMedium" lineClamp={1} css={{ color: '$on-surface' }}>
                  {i18n().modules.hub.classroom.pages.list.className}
                </Typography>
              </Grid>

              <Grid xl={2} css={{ display: 'flex', alignItems: 'center', gap: '$md' }}>
                <Typography variant="titleMedium" lineClamp={1} css={{ color: '$on-surface' }}>
                  {i18n().modules.hub.classroom.pages.list.code}
                </Typography>
              </Grid>
              <Grid xl={4} css={{ display: 'flex', alignItems: 'center', gap: '$md' }}>
                <Typography variant="titleMedium" lineClamp={1} css={{ color: '$on-surface' }}>
                  {i18n().modules.hub.classroom.pages.list.schoolYearName}
                </Typography>
              </Grid>
              <Grid xl={3} css={{ display: 'flex', justifyContent: 'flex-end' }}>
                <IconButton
                  variant="standard"
                  disabled={!classroomsIdsToRemove.length}
                  onClick={() =>
                    setRemoveClassroomDialog((oldData) => ({ ...oldData!, isBulk: true }))
                  }
                >
                  <DeleteIcon size={24} />
                </IconButton>
              </Grid>
            </Grid>
            <UL
              css={{
                display: 'flex',
                flexDirection: 'column',
                gap: '$sm',
                ...(listClassroom.isFetching && {
                  ...(listClassroom.data.lastPage <= 1 && { flex: 1 }),
                  alignItems: 'center',
                  justifyContent: 'center',
                  position: 'relative'
                })
              }}
            >
              {listClassroom.isFetching && <Loader size={80} />}

              {listClassroom.data.registers.map((classroom) => (
                <BaseCardButton
                  key={classroom.id}
                  onClick={() => {
                    navigate('/data-management/levels-and-classes/classroom/classroom-users', {
                      state: {
                        classroom,
                        schoolYear: {
                          name: selectedSchoolYear?.title
                        }
                      }
                    })
                  }}
                  css={{
                    opacity: listClassroom.isFetching ? '$transparent' : '$default',
                    borderRadius: '$lg',
                    backgroundColor: '$surface-1',
                    cursor: 'pointer',
                    '& .BaseCardButton-StateLayer': {
                      flexDirection: 'row',
                      alignItems: 'center',
                      gap: '$lg',
                      padding: '$md $lg'
                    }
                  }}
                >
                  <Grid spacing="$md" css={{ flex: 1, alignItems: 'center' }}>
                    <Grid xl={3} css={{ display: 'flex', alignItems: 'center', gap: '$md' }}>
                      <Checkbox
                        inputProps={{
                          checked: classroomsIdsToRemove.includes(classroom.id),
                          onClick: () => handleSelectClassrooms(classroom.id)
                        }}
                      />
                      <Typography lineClamp={2} variant="bodyMedium" css={{ color: '$on-surface' }}>
                        {classroom.name}
                      </Typography>
                    </Grid>

                    <Grid xl={2}>
                      <Typography lineClamp={2} variant="bodyMedium" css={{ color: '$on-surface' }}>
                        {classroom.code}
                      </Typography>
                    </Grid>
                    <Grid xl={2}>
                      <Typography lineClamp={2} variant="bodyMedium" css={{ color: '$on-surface' }}>
                        {classroom.levelFormatted.code}
                      </Typography>
                    </Grid>
                    <Grid
                      xl={5}
                      css={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        gap: '$md'
                      }}
                    >
                      <LinkButton
                        LeadingIcon={<PersonAddIcon size={18} />}
                        stopPropagation
                        onClick={() => {
                          navigate(
                            '/data-management/levels-and-classes/classroom/classroom-users/form-student',
                            {
                              state: {
                                classroom,
                                schoolYear: {
                                  name: selectedSchoolYear?.title
                                }
                              }
                            }
                          )
                        }}
                      >
                        {i18n().modules.hub.classroom.pages.list.students}
                      </LinkButton>
                      <LinkButton
                        LeadingIcon={<PersonAddIcon size={18} />}
                        stopPropagation
                        onClick={() =>
                          navigate(
                            '/data-management/levels-and-classes/classroom/classroom-users/form-teacher',
                            {
                              state: {
                                classroom,
                                schoolYear: {
                                  name: selectedSchoolYear?.title
                                }
                              }
                            }
                          )
                        }
                      >
                        {i18n().modules.hub.classroom.pages.list.teachers}
                      </LinkButton>
                      <IconButton
                        variant="standard"
                        stopPropagation
                        onClick={() =>
                          navigate(
                            `/data-management/levels-and-classes/classroom/form/${classroom.id}`,
                            {
                              state: {
                                schoolYear: {
                                  name: selectedSchoolYear?.title,
                                  id: selectedSchoolYear?.id
                                },
                                page: listClassroomParams.page
                              }
                            }
                          )
                        }
                      >
                        <EditIcon size={24} />
                      </IconButton>
                      <IconButton
                        variant="standard"
                        stopPropagation
                        onClick={() => {
                          setClassroomsIdToRemove([classroom.id])
                          setRemoveClassroomDialog({
                            id: classroom.id,
                            title: classroom.name,
                            isBulk: false
                          })
                        }}
                      >
                        <DeleteIcon size={24} />
                      </IconButton>
                    </Grid>
                  </Grid>
                </BaseCardButton>
              ))}
            </UL>
            {listClassroom.data.lastPage > 1 && (
              <Pagination
                lastPage={listClassroom.data.lastPage}
                page={listClassroomParams.page ?? 1}
                setPage={(page) => setListClassroomParams((oldState) => ({ ...oldState, page }))}
                css={{ paddingTop: '$sm' }}
              />
            )}
          </Div>
        )}
      </Div>
    </Main>
  )
}
