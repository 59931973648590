import { ClassLevelCode } from '@/modules/hub/classroom/contracts'

export const classroomPt = {
  resources: {
    listClassLevel: 'Listar anos escolares',
    listClassroom: 'Listar turmas',
    removeClassroom: 'Remover turma',
    updateClassroom: 'Atualizar turma',
    showClassroom: 'Exibir turma',
    createClassroom: 'Criar turma',
    bulkEnrollments: 'Enturmar usuários da turma',
    removeBulkEnrollments: 'Remover usuários da turma',
    shortStudent: 'Listar estudantes'
  },
  components: {
    filter: {
      title: 'Opções de filtragem',
      input: {
        search: 'Buscar turma...',
        select: 'Selecionar ano escolar'
      }
    },
    header: {
      selectClass: 'Selecione uma turma',
      code: 'Código',
      level: 'Nível'
    },
    emptyList: 'Ops! Ainda não temos turmas para exibir'
  },

  abbreviatedSegmentClassLevel: <
    Record<
      | keyof typeof ClassLevelCode
      | 'EF1_ESP'
      | 'EF1'
      | 'EF2_ESP'
      | 'EF2'
      | 'EI_ESP'
      | 'EI'
      | 'EJA'
      | 'EM_ESP'
      | 'EM'
      | 'EPRO',
      string
    >
  >{
    CHILD_EDUCATION: ClassLevelCode.CHILD_EDUCATION,
    EI: 'Educação Infantil',
    CHILD_EDUCATION_1: ClassLevelCode.CHILD_EDUCATION_1,
    CHILD_EDUCATION_2: ClassLevelCode.CHILD_EDUCATION_2,
    CHILD_EDUCATION_3: ClassLevelCode.CHILD_EDUCATION_3,
    CHILD_EDUCATION_4: ClassLevelCode.CHILD_EDUCATION_4,
    CHILD_EDUCATION_5: ClassLevelCode.CHILD_EDUCATION_5,
    GRUPO_ONE: ClassLevelCode.GRUPO_ONE,
    GRUPO_TWO: ClassLevelCode.GRUPO_TWO,
    GRUPO_TREE: ClassLevelCode.GRUPO_TREE,
    GRUPO_FOUR: ClassLevelCode.GRUPO_FOUR,
    GRUPO_FIVE: ClassLevelCode.GRUPO_FIVE,
    EI_ESP: 'Educação Infantil Especial',
    GRUPO_ONE_ESP: ClassLevelCode.GRUPO_ONE_ESP,
    GRUPO_TWO_ESP: ClassLevelCode.GRUPO_TWO_ESP,
    GRUPO_TREE_ESP: ClassLevelCode.GRUPO_TREE_ESP,
    GRUPO_FOUR_ESP: ClassLevelCode.GRUPO_FOUR_ESP,
    GRUPO_FIVE_ESP: ClassLevelCode.GRUPO_FIVE_ESP,
    EF1: 'Ensino Fundamental 1',
    FIRST_YEAR: ClassLevelCode.FIRST_YEAR,
    SECOND_YEAR: ClassLevelCode.SECOND_YEAR,
    THIRD_YEAR: ClassLevelCode.THIRD_YEAR,
    FORTH_YEAR: ClassLevelCode.FORTH_YEAR,
    FIFTH_YEAR: ClassLevelCode.FIFTH_YEAR,
    EF2: 'Ensino Fundamental 2',
    SIXTH_YEAR: ClassLevelCode.SIXTH_YEAR,
    SEVENTH_YEAR: ClassLevelCode.SEVENTH_YEAR,
    EIGHTH_YEAR: ClassLevelCode.EIGHTH_YEAR,
    NINTH_YEAR: ClassLevelCode.NINTH_YEAR,
    EF1_ESP: 'Ensino Fundamental 1 Especial',
    FIRST_YEAR_ESP: ClassLevelCode.FIRST_YEAR_ESP,
    SECOND_YEAR_ESP: ClassLevelCode.SECOND_YEAR_ESP,
    THIRD_YEAR_ESP: ClassLevelCode.THIRD_YEAR_ESP,
    FORTH_YEAR_ESP: ClassLevelCode.FORTH_YEAR_ESP,
    FIFTH_YEAR_ESP: ClassLevelCode.FIFTH_YEAR_ESP,
    EF2_ESP: 'Ensino Fundamental 2 Especial',
    SIXTH_YEAR_ESP: ClassLevelCode.SIXTH_YEAR_ESP,
    SEVENTH_YEAR_ESP: ClassLevelCode.SEVENTH_YEAR_ESP,
    EIGHTH_YEAR_ESP: ClassLevelCode.EIGHTH_YEAR_ESP,
    NINTH_YEAR_ESP: ClassLevelCode.NINTH_YEAR_ESP,
    EM: 'Ensino Médio',
    FIRST_YEAR_EM: ClassLevelCode.FIRST_YEAR_EM,
    SECOND_YEAR_EM: ClassLevelCode.SECOND_YEAR_EM,
    THIRD_YEAR_EM: ClassLevelCode.THIRD_YEAR_EM,
    EM_ESP: 'Ensino Médio Especial',
    FIRST_YEAR_EM_ESP: ClassLevelCode.FIRST_YEAR_EM_ESP,
    SECOND_YEAR_EM_ESP: ClassLevelCode.SECOND_YEAR_EM_ESP,
    THIRD_YEAR_EM_ESP: ClassLevelCode.THIRD_YEAR_EM_ESP,
    CHILD_EDUCATION_1_SP: ClassLevelCode.CHILD_EDUCATION_1_SP,
    CHILD_EDUCATION_2_SP: ClassLevelCode.CHILD_EDUCATION_2_SP,
    CHILD_EDUCATION_3_SP: ClassLevelCode.CHILD_EDUCATION_3_SP,
    CHILD_EDUCATION_4_SP: ClassLevelCode.CHILD_EDUCATION_4_SP,
    CHILD_EDUCATION_5_SP: ClassLevelCode.CHILD_EDUCATION_5_SP,
    EJA: 'EJA',
    EJAEF_ONE: ClassLevelCode.EJAEF_ONE,
    EJAEF_SECOND: ClassLevelCode.EJAEF_SECOND,
    EJA_EM: ClassLevelCode.EJA_EM,
    EPRO: 'Educação Profissional',
    PRO: ClassLevelCode.PRO,
    EPEM: ClassLevelCode.EPEM
  },

  fullClassLevel: <
    Record<
      | keyof typeof ClassLevelCode
      | 'EF1_ESP'
      | 'EF1'
      | 'EF2_ESP'
      | 'EF2'
      | 'EI_ESP'
      | 'EI'
      | 'EJA'
      | 'EM_ESP'
      | 'EM'
      | 'EPRO',
      string
    >
  >{
    CHILD_EDUCATION: 'Educação Infantil',
    EI: 'Educação Infantil',
    CHILD_EDUCATION_1: 'Educação Infantil - Grupo 1',
    CHILD_EDUCATION_2: 'Educação Infantil - Grupo 2',
    CHILD_EDUCATION_3: 'Educação Infantil - Grupo 3',
    CHILD_EDUCATION_4: 'Educação Infantil - Grupo 4',
    CHILD_EDUCATION_5: 'Educação Infantil - Grupo 5',
    GRUPO_ONE: 'Educação Infantil - Grupo 1',
    GRUPO_TWO: 'Educação Infantil - Grupo 2',
    GRUPO_TREE: 'Educação Infantil - Grupo 3',
    GRUPO_FOUR: 'Educação Infantil - Grupo 4',
    GRUPO_FIVE: 'Educação Infantil - Grupo 5',
    EI_ESP: 'Educação Infantil Especial',
    GRUPO_ONE_ESP: 'Educação Infantil Especial - Grupo 1',
    GRUPO_TWO_ESP: 'Educação Infantil Especial - Grupo 2',
    GRUPO_TREE_ESP: 'Educação Infantil Especial - Grupo 3',
    GRUPO_FOUR_ESP: 'Educação Infantil Especial - Grupo 4',
    GRUPO_FIVE_ESP: 'Educação Infantil Especial - Grupo 5',
    EF1: 'Ensino Fundamental 1',
    FIRST_YEAR: 'Ensino Fundamental 1 - 1º ano',
    SECOND_YEAR: 'Ensino Fundamental 1 - 2º ano',
    THIRD_YEAR: 'Ensino Fundamental 1 - 3º ano',
    FORTH_YEAR: 'Ensino Fundamental 1 - 4º ano',
    FIFTH_YEAR: 'Ensino Fundamental 1 - 5º ano',
    EF2: 'Ensino Fundamental 2',
    SIXTH_YEAR: 'Ensino Fundamental 2 - 6º ano',
    SEVENTH_YEAR: 'Ensino Fundamental 2 - 7º ano',
    EIGHTH_YEAR: 'Ensino Fundamental 2 - 8º ano',
    NINTH_YEAR: 'Ensino Fundamental 2 - 9º ano',
    EF1_ESP: 'Ensino Fundamental 1 Especial',
    FIRST_YEAR_ESP: 'Ensino Fundamental 1 Especial - 1º ano',
    SECOND_YEAR_ESP: 'Ensino Fundamental 1 Especial - 2º ano',
    THIRD_YEAR_ESP: 'Ensino Fundamental 1 Especial - 3º ano',
    FORTH_YEAR_ESP: 'Ensino Fundamental 1 Especial - 4º ano',
    FIFTH_YEAR_ESP: 'Ensino Fundamental 1 Especial - 5º ano',
    EF2_ESP: 'Ensino Fundamental 2 Especial',
    SIXTH_YEAR_ESP: 'Ensino Fundamental 2 Especial - 6º ano',
    SEVENTH_YEAR_ESP: 'Ensino Fundamental 2 Especial - 7º ano',
    EIGHTH_YEAR_ESP: 'Ensino Fundamental 2 Especial - 8º ano',
    NINTH_YEAR_ESP: 'Ensino Fundamental 2 Especial - 9º ano',
    EM: 'Ensino Médio',
    OTHERS: 'Ensino Médio - Eletivas',
    FIRST_YEAR_EM: 'Ensino Médio - 1ª Série',
    SECOND_YEAR_EM: 'Ensino Médio - 2ª Série',
    THIRD_YEAR_EM: 'Ensino Médio - 3ª Série',
    EM_ESP: 'Ensino Médio Especial',
    FIRST_YEAR_EM_ESP: 'Ensino Médio Especial - 1ª Série',
    SECOND_YEAR_EM_ESP: 'Ensino Médio Especial - 2ª Série',
    THIRD_YEAR_EM_ESP: 'Ensino Médio Especial - 3ª Série',
    CHILD_EDUCATION_1_SP: 'Ensino Médio Especial - Grupo 1',
    CHILD_EDUCATION_2_SP: 'Ensino Médio Especial - Grupo 2',
    CHILD_EDUCATION_3_SP: 'Ensino Médio Especial - Grupo 3',
    CHILD_EDUCATION_4_SP: 'Ensino Médio Especial - Grupo 4',
    CHILD_EDUCATION_5_SP: 'Ensino Médio Especial - Grupo 5',
    EJA: 'EJA',
    EJAEF_ONE: 'EJA - EF1',
    EJAEF_SECOND: 'EJA - EF2',
    EJA_EM: 'EJA - EM',
    EPRO: 'Educação Profissional',
    PRO: 'Educação Profissional',
    EPEM: 'Educação Profissional Integrada ao Ensino Médio'
  },
  levelClassLevel: <
    Record<
      | keyof typeof ClassLevelCode
      | 'EF1_ESP'
      | 'EF1'
      | 'EF2_ESP'
      | 'EF2'
      | 'EI_ESP'
      | 'EI'
      | 'EJA'
      | 'EM_ESP'
      | 'EM'
      | 'EPRO',
      string
    >
  >{
    CHILD_EDUCATION: 'Educação Infantil',
    EI: 'Educação Infantil',
    GRUPO_ONE: 'Grupo 1',
    GRUPO_TWO: 'Grupo 2',
    GRUPO_TREE: 'Grupo 3',
    GRUPO_FOUR: 'Grupo 4',
    GRUPO_FIVE: 'Grupo 5',
    EI_ESP: 'Educação Infantil Especial',
    GRUPO_ONE_ESP: 'Grupo 1 Especial',
    GRUPO_TWO_ESP: 'Grupo 2 Especial',
    GRUPO_TREE_ESP: 'Grupo 3 Especial',
    GRUPO_FOUR_ESP: 'Grupo 4 Especial',
    GRUPO_FIVE_ESP: 'Grupo 5 Especial',
    EF1: 'Ensino Fundamental 1',
    FIRST_YEAR: '1º ano',
    SECOND_YEAR: '2º ano',
    THIRD_YEAR: '3º ano',
    FORTH_YEAR: '4º ano',
    FIFTH_YEAR: '5º ano',
    EF2: 'Ensino Fundamental 2',
    SIXTH_YEAR: '6º ano',
    SEVENTH_YEAR: '7º ano',
    EIGHTH_YEAR: '8º ano',
    NINTH_YEAR: '9º ano',
    EF1_ESP: 'Ensino Fundamental 1 Especial',
    FIRST_YEAR_ESP: '1º ano Especial',
    SECOND_YEAR_ESP: '2º ano Especial',
    THIRD_YEAR_ESP: '3º ano Especial',
    FORTH_YEAR_ESP: '4º ano Especial',
    FIFTH_YEAR_ESP: '5º ano Especial',
    EF2_ESP: 'Ensino Fundamental 2 Especial',
    SIXTH_YEAR_ESP: '6º ano Especial',
    SEVENTH_YEAR_ESP: '7º ano Especial',
    EIGHTH_YEAR_ESP: '8º ano Especial',
    NINTH_YEAR_ESP: '9º ano Especial',
    EM: 'Ensino Médio',
    FIRST_YEAR_EM: '1ª Série',
    SECOND_YEAR_EM: '2ª Série',
    THIRD_YEAR_EM: '3ª Série',
    EM_ESP: 'Ensino Médio Especial',
    FIRST_YEAR_EM_ESP: '1ª Série Especial',
    SECOND_YEAR_EM_ESP: '2ª Série Especial',
    THIRD_YEAR_EM_ESP: '3ª Série Especial',
    EJA: 'EJA',
    EJAEF_ONE: 'EJA Ensino Fundamental 1',
    EJAEF_SECOND: 'EJA Ensino Fundamental 2',
    EJA_EM: 'EJA Ensino Médio',
    EPRO: 'Educação Profissional',
    PRO: 'Educação Profissional',
    EPEM: 'Educação Profissional Integrada ao Ensino Médio'
  },
  pages: {
    list: {
      pageTitle: 'Turmas',
      allYears: 'Todos os anos escolares',
      addNewClass: ' Incluir nova turma',
      students: 'Estudantes',
      teachers: 'Professores',
      appBar: {
        title: 'Gerenciamento de dados',
        breadcrumbs: {
          overview: 'Visão geral',
          educationLevelsAndClasses: 'Níveis de ensino e turmas',
          schoolClasses: 'Turmas'
        },
        buttonTitle: 'Incluir nova turma'
      },
      removeDialog: {
        title: (isBulk: boolean): string => (isBulk ? 'Remover turmas' : 'Remover turma'),
        contentTextMain: 'Tem certeza que deseja remover o cadastro do(a) ',
        bulkContentTextMain: 'Tem certeza que deseja remover o cadastro das turmas selecionadas?',
        selectedClassroom: (name: string | undefined): string => `${name}?`,
        refuse: 'Cancelar',
        accept: 'Sim, quero remover'
      },
      filter: {
        title: 'Opções de filtragem',
        schoolYear: 'Selecionar ano escolar',
        search: 'Buscar...'
      },
      schoolYear: 'Ano letivo',
      className: 'Nome da turma',
      code: ' Código',
      schoolYearName: 'Ano escolar',
      emptyList: 'Ops! Ainda não temos turmas para exibir',
      buttonTitle: 'Incluir nova turma',
      alert: {
        remove: 'Turma removida com sucesso!',
        createClassroom: 'Turma criada com sucesso!',
        updateClassroom: 'Turma atualizada com sucesso!'
      }
    },
    form: {
      pageTitle: (isEditing: boolean): string => `${isEditing ? 'Editar' : 'Nova'} turma`,
      appBar: {
        title: 'Gerenciamento de dados',
        breadcrumbs: {
          overview: 'Visão geral',
          educationLevelsAndClasses: 'Níveis de ensino e turmas',
          classroom: 'Turmas',
          newClassroom: 'Nova turma',
          editClassroom: 'Editar turma'
        },
        buttonTitle: 'Incluir novo ano letivo'
      },
      stepper: {
        classroomData: {
          title: '* Campo de preenchimento obrigatório',
          stepTitle: 'Dados da turma',
          name: 'Nome da turma',
          code: 'Código',
          associatedSchoolYear: 'Ano escolar associado',

          validators: {
            requiredAssociatedSchoolYear: 'Você precisa selecionar um ano escolar associado',
            requiredName: 'Parece que você não inseriu o nome da turma ainda',
            minNameLength: 'É necessário inserir um nome com no mínimo 3 caracteres',
            requiredCode: 'Parece que você não inseriu o código da turma ainda',
            minCodeLength: 'É necessário inserir um código com no mínimo 3 caracteres',
            nameDisciplineExist: 'Este nome de turma já existe neste ano escolar',
            codeDisciplineExist: 'Este código já existe em outra turma'
          }
        },
        preview: {
          stepTitle: 'Pré-visualização',
          schoolYear: 'Ano letivo:',
          saveClassroom: 'Finalizar cadastro',
          associatedSchoolYear: 'Ano escolar associado:',
          classroomCode: 'Código da turma:',
          code: 'Código:'
        }
      }
    },
    enrollments: {
      list: {
        pageTitle: 'Estudantes da turma',
        students: 'Estudantes',
        teachers: 'Professores',
        studentsClassroom: 'Estudantes da turma',
        includeStudents: 'Incluir estudantes',
        editStudent: 'Editar estudantes',
        bondDate: 'Data de vínculo',
        useImage: 'Uso de imagem',
        allows: 'Permite',
        emptyList: {
          title: 'Ops! Esta turma ainda não tem estudantes!',
          subTitle: 'Você precisará ter usuários cadastrados para vincular estudantes a este turma.'
        },
        appBar: {
          title: 'Gerenciamento de dados',
          breadcrumbs: {
            overview: 'Visão geral',
            educationLevelsAndClasses: 'Níveis de ensino e turmas',
            schoolClasses: 'Turmas'
          }
        }
      },
      form: {
        pageTitle: (name: string): string => `${name} (Inclusão de estudantes)`,
        pageTitleTeacher: (name: string): string => `${name} (Inclusão de professores)`,
        alertMessageTitle: 'É permitido o vínculo de no máximo 200 usuários por vez',
        includeStudents: 'Incluir estudantes',
        students: 'Estudantes',
        emptyListStudent: 'Ops! Não temos mais estudantes para exibir',
        emptyListClassroomStudent: 'Ops! Esta turma ainda não tem estudantes!',
        classroomStudents: 'Estudantes da turma',
        textFieldLabel: 'Buscar por nome ou matrícula...',
        cancel: 'Cancelar',
        saveClassroom: 'Salvar alterações na turma',
        tooltipLabel: 'Este estudante já está vinculado à(s) turma(s):',
        alertMessage: {
          bulkEnrollments: 'Usuários vinculados à turma com sucesso!',
          removeBulkEnrollments: 'Usuários removidos da turma com sucesso!'
        },
        appBar: {
          title: 'Gerenciamento de dados',
          breadcrumbs: {
            overview: 'Visão geral',
            educationLevelsAndClasses: 'Níveis de ensino e turmas',
            schoolClasses: 'Turmas',
            includeStudent: (name: string): string => `${name} (Inclusão de estudantes)`,
            includeTeacher: (name: string): string => `${name} (Inclusão de professores)`
          }
        }
      },
      teacher: {
        list: {
          title: 'Professores da turma',
          includeTeachers: 'Incluir professores',
          editTeachers: 'Editar professores',
          goToDisciplineRegistration: 'Ir para o cadastro de disciplinas',
          withoutTeachers: 'Ops! Esta turma ainda não tem professores!',
          withoutDisciplines:
            'Você precisará ter disciplinas cadastradas para vincular professores a esta turma.',
          withoutSchoolTeachers:
            'Você precisará ter usuários cadastrados para vincular professores a este turma.',
          teachersName: 'Professor(es)',
          enrollmentDate: 'Data de vínculo',
          discipline: 'Disciplina',
          assingDiscipline: 'Atribuir disciplinas'
        },
        form: {
          stepper: {
            selectTeachers: {
              stepTitle: 'Adicionar professores'
            },
            selectDiscipline: {
              stepTitle: 'Atribuir disciplinas',
              done: 'Salvar alterações na turma'
            }
          },
          enrollment: {
            addTeaches: 'Incluir professores',
            addedTeachers: 'Professores da turma',
            title: 'Professor(es)',
            noHaveTeachers: 'Ops! Não temos mais professores para exibir',
            withoutTeachers: 'Ops! Esta turma ainda não tem professores!',
            searchByName: 'Buscar por nome...',
            youNeedRegister:
              'Você precisará ter usuários cadastrados para vincular professores a este turma.'
          },
          textDialog: {
            title: 'Deseja sair da tela de inclusão?',
            contentText:
              'Para gravar os professores na turma, será necessário clicar no botão ”Salvar alterações na turma”, disponível na última etapa. Caso contrário, os dados preenchidos serão perdidos.',
            refuseAction: 'Voltar',
            acceptAction: 'Sair'
          },
          textDialogNoHaveDiscipline: {
            title: 'Professores sem disciplina',
            contentText:
              'Um ou mais professores não possuem disciplina vinculada. É preciso atribuir uma disciplina ou remover o vínculo do usuário com a turma.',
            acceptAction: 'Ok'
          }
        }
      }
    }
  }
}
