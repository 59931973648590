import {
  UseMutationResult,
  UseQueryResult,
  keepPreviousData,
  useMutation,
  useQuery,
  useQueryClient
} from '@tanstack/react-query'

import { ApplicationException } from '@/common/exceptions'
import { useErrorHandler } from '@/common/hooks'
import { ListClassroomStudentHookResult } from '@/modules/hub/classroom/contracts'

import {
  ListShortStudentHookParams,
  ListShortStudentHookResult,
  ListTeacherHookParams,
  ListTeacherHookResult,
  ListUserHookParams,
  ListUserHookResult,
  UpdateStudentConsentHookParams
} from './contracts'
import {
  listShortStudent,
  listTeacher,
  listUserService,
  updateStudentConsentService
} from './services'

const hookKey = 'users'

export function useListUser({
  model,
  ignoreLogout,
  queryOptions,
  onSuccess,
  onError
}: ListUserHookParams): UseQueryResult<ListUserHookResult, ApplicationException> {
  const { handleError } = useErrorHandler({ ignoreLogout })

  return useQuery({
    queryKey: [hookKey, 'list', model],
    placeholderData: keepPreviousData,
    queryFn: async () => {
      try {
        const listUsers = await listUserService(model)
        onSuccess?.(listUsers)
        return listUsers
      } catch (error) {
        const parsedError = error as ApplicationException
        handleError({ error: parsedError })
        onError?.({ error: parsedError })
        throw parsedError
      }
    },
    ...queryOptions
  })
}

export function useListShortStudent({
  model,
  queryOptions,
  onSuccess,
  onError
}: ListShortStudentHookParams): UseQueryResult<ListShortStudentHookResult, ApplicationException> {
  const { handleError } = useErrorHandler()
  return useQuery({
    queryKey: [hookKey, 'listShortStudent', model],
    placeholderData: keepPreviousData,
    queryFn: async () => {
      try {
        const shortStudent = await listShortStudent(model)
        onSuccess?.(shortStudent)
        return shortStudent
      } catch (error) {
        const parsedError = error as ApplicationException
        handleError({ error: parsedError })
        onError?.({ error: parsedError })
        throw parsedError
      }
    },
    ...queryOptions
  })
}

export function useUpdateStudentConsent(): UseMutationResult<
  void,
  ApplicationException,
  UpdateStudentConsentHookParams
> {
  const { handleError } = useErrorHandler()
  const queryClient = useQueryClient()
  return useMutation({
    mutationKey: [hookKey, 'updateConsent'],
    mutationFn: async ({
      model,
      onSuccess,
      onError,
      page,
      perPage,
      classId
    }: UpdateStudentConsentHookParams) => {
      try {
        await updateStudentConsentService(model)
        queryClient.setQueryData(
          [
            'classroom',
            'listStudent',
            {
              classId,
              page,
              perPage
            }
          ],
          (oldProfiles: ListClassroomStudentHookResult | undefined) =>
            oldProfiles && {
              ...oldProfiles,
              registers: oldProfiles.registers.map((profile) =>
                Number(profile.id) === model.studentId
                  ? { ...profile, allowedImageUseOnEdtech: model.consent }
                  : profile
              )
            }
        )
        onSuccess?.()
      } catch (error) {
        const parsedError = error as ApplicationException
        handleError({ error: parsedError })
        onError?.({ error: parsedError })
        throw parsedError
      }
    }
  })
}

export function useListTeacher({
  model,
  queryOptions,
  onSuccess,
  onError
}: ListTeacherHookParams): UseQueryResult<ListTeacherHookResult, ApplicationException> {
  const { handleError } = useErrorHandler()

  return useQuery({
    queryKey: [hookKey, 'listTeacher', model],
    placeholderData: keepPreviousData,
    queryFn: async () => {
      try {
        const teachers = await listTeacher(model)
        onSuccess?.(teachers)
        return teachers
      } catch (error) {
        const parsedError = error as ApplicationException
        handleError({ error: parsedError })
        onError?.({ error: parsedError })
        throw parsedError
      }
    },
    ...queryOptions
  })
}
