import { BaseCard } from '@positivote/design-system/components/BaseCard'
import { Button } from '@positivote/design-system/components/Button'
import { Div } from '@positivote/design-system/components/Div'
import { Grid } from '@positivote/design-system/components/Grid'
import { IconWrapper } from '@positivote/design-system/components/IconWrapper'
import { Image } from '@positivote/design-system/components/Image'
import { Loader } from '@positivote/design-system/components/Loader'
import { Main } from '@positivote/design-system/components/Main'
import { Pagination } from '@positivote/design-system/components/Pagination'
import { Switch } from '@positivote/design-system/components/Switch'
import { Typography } from '@positivote/design-system/components/Typography'
import { UL } from '@positivote/design-system/components/UL'
import { AddCircleIcon } from '@positivote/design-system/icons/AddCircle'
import { EditIcon } from '@positivote/design-system/icons/Edit'
import { GroupsIcon } from '@positivote/design-system/icons/Groups'
import { PersonIcon } from '@positivote/design-system/icons/Person'
import { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { EmptyList } from '@/common/components/EmptyList'
import { changePageTitle } from '@/common/helpers'
import { i18n } from '@/common/i18n'
import {
  ClassroomFormatted,
  ListClassroomStudentHookParams
} from '@/modules/hub/classroom/contracts'
import { useListClassroomStudent } from '@/modules/hub/classroom/hooks'
import { useListShortStudent, useUpdateStudentConsent } from '@/modules/hub/users/hooks'

export function ListStudent(): JSX.Element {
  changePageTitle(i18n().modules.hub.classroom.pages.enrollments.list.pageTitle)
  const location = useLocation()
  const navigate = useNavigate()
  const updateStudentConsent = useUpdateStudentConsent()
  const locationState = location.state as {
    classroom: ClassroomFormatted
    schoolYear: { name: string }
  } | null

  const allStudentsToEnrollments = useListShortStudent({
    model: {
      ignoreClassroomId: locationState?.classroom.id
    },
    queryOptions: {
      enabled: !!locationState
    }
  })
  const [listClassroomStudentsParams, setListClassroomStudentsParams] = useState<
    ListClassroomStudentHookParams['model']
  >({
    page: 1,
    perPage: 15,
    classId: locationState?.classroom.id as unknown as string
  })
  function handleImageConsent(profileId: string, consent: boolean): void {
    updateStudentConsent.mutate({
      model: {
        consent: !consent,
        studentId: Number(profileId)
      },
      page: listClassroomStudentsParams.page!,
      perPage: listClassroomStudentsParams.perPage!,
      classId: locationState?.classroom.id
    })
  }
  const listClassroomStudents = useListClassroomStudent({
    model: listClassroomStudentsParams,
    queryOptions: {
      enabled: !!locationState?.classroom.id && !!allStudentsToEnrollments.data?.registers
    }
  })

  const isLoading = allStudentsToEnrollments.isFetching || listClassroomStudents.isFetching

  return (
    <Main css={{ display: 'flex', flexDirection: 'column', flex: 1, overflowX: 'hidden' }}>
      <Div
        css={{
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
          gap: '$2xs',
          overflowY: 'auto',
          '@sm': { padding: '$md' }
        }}
      >
        {!listClassroomStudents.data?.registers.length ? (
          <Div
            css={{
              display: 'flex',
              flexDirection: 'column',
              flex: 1
            }}
          >
            {isLoading && (
              <Div
                css={{
                  display: 'flex',
                  flexDirection: 'column',
                  flex: 1,
                  alignItems: 'center',
                  justifyContent: 'center'
                }}
              >
                <Loader size={80} />
              </Div>
            )}
            {!isLoading && (
              <Grid
                xl={12}
                css={{
                  display: 'flex',
                  flexDirection: 'column',
                  borderWidth: '$thin',
                  borderStyle: 'solid',
                  borderRadius: '$xl',
                  borderColor: '$outline-variant',
                  padding: '$md',
                  gap: '$md',
                  flex: 1
                }}
              >
                <Div css={{ display: 'flex', gap: '$2xs' }}>
                  <GroupsIcon />
                  <Typography variant="titleLarge" css={{ color: '$on-surface' }}>
                    {i18n().modules.hub.classroom.pages.enrollments.list.studentsClassroom}
                  </Typography>
                </Div>
                <Div
                  css={{
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'column',
                    gap: '$md',
                    padding: '$md',
                    flex: 1
                  }}
                >
                  {!allStudentsToEnrollments.data?.registers.length ? (
                    <Div css={{ display: 'flex', flex: 1 }}>
                      <EmptyList
                        title={i18n().modules.hub.classroom.pages.enrollments.list.emptyList.title}
                        subTitle={
                          i18n().modules.hub.classroom.pages.enrollments.list.emptyList.subTitle
                        }
                      />
                    </Div>
                  ) : (
                    <>
                      <EmptyList
                        title={i18n().modules.hub.classroom.pages.enrollments.list.emptyList.title}
                      />
                      <Button
                        onClick={() =>
                          navigate(
                            '/data-management/levels-and-classes/classroom/classroom-users/form-student',
                            {
                              state: {
                                classroom: locationState?.classroom,
                                schoolYear: {
                                  name: locationState?.schoolYear.name
                                }
                              }
                            }
                          )
                        }
                        variant="tonal"
                        LeadingIcon={<AddCircleIcon size={18} />}
                      >
                        {i18n().modules.hub.classroom.pages.enrollments.list.includeStudents}
                      </Button>
                    </>
                  )}
                </Div>
              </Grid>
            )}
          </Div>
        ) : (
          <Grid
            xl={12}
            css={{
              display: 'flex',
              flexDirection: 'column',
              borderWidth: '$thin',
              borderStyle: 'solid',
              borderRadius: '$xl',
              borderColor: '$outline-variant',
              padding: '$md',
              gap: '$2xs',
              overflow: 'hidden'
            }}
          >
            <Div
              css={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between'
              }}
            >
              <Div css={{ display: 'flex', gap: '$2xs' }}>
                <GroupsIcon />
                <Typography variant="titleLarge" css={{ color: '$on-surface' }}>
                  {i18n().modules.hub.classroom.pages.enrollments.list.studentsClassroom}
                </Typography>
              </Div>

              <Button
                onClick={() =>
                  navigate(
                    '/data-management/levels-and-classes/classroom/classroom-users/form-student',
                    {
                      state: {
                        classroom: locationState?.classroom,
                        schoolYear: {
                          name: locationState?.schoolYear.name
                        }
                      }
                    }
                  )
                }
                variant="tonal"
                LeadingIcon={<EditIcon size={18} />}
              >
                {i18n().modules.hub.classroom.pages.enrollments.list.editStudent}
              </Button>
            </Div>
            <Grid spacing="$md" css={{ padding: '$md' }}>
              <Grid xl={5} css={{ display: 'flex', alignItems: 'center', gap: '$md' }}>
                <Typography variant="titleMedium" lineClamp={1} css={{ color: '$on-surface' }}>
                  {i18n().modules.hub.classroom.pages.enrollments.list.students}
                </Typography>
              </Grid>

              <Grid xl={2} css={{ display: 'flex', alignItems: 'center', gap: '$md' }}>
                <Typography variant="titleMedium" lineClamp={1} css={{ color: '$on-surface' }}>
                  {i18n().modules.hub.classroom.pages.enrollments.list.bondDate}
                </Typography>
              </Grid>
              <Grid xl={2} css={{ display: 'flex', alignItems: 'center', gap: '$md' }}>
                <Typography variant="titleMedium" lineClamp={1} css={{ color: '$on-surface' }}>
                  {i18n().modules.hub.classroom.pages.enrollments.list.useImage}
                </Typography>
              </Grid>
            </Grid>
            <UL
              css={{
                display: 'flex',
                flexDirection: 'column',
                overflow: 'auto',
                gap: '$sm',
                ...(listClassroomStudents.isFetching && {
                  ...(listClassroomStudents.data.lastPage <= 1 && { flex: 1 }),
                  alignItems: 'center',
                  justifyContent: 'center',
                  position: 'relative'
                })
              }}
            >
              {listClassroomStudents.isFetching && <Loader size={80} />}
              {listClassroomStudents.data.registers.map((option) => (
                <BaseCard
                  key={option.id}
                  css={{
                    opacity: listClassroomStudents.isFetching ? '$transparent' : '$default',
                    borderRadius: '$lg',
                    backgroundColor: '$surface-2',
                    minHeight: '64px',
                    '& .BaseCard-StateLayer': {
                      flexDirection: 'row',
                      alignItems: 'center',
                      padding: '$sm $md',
                      gap: '$md'
                    }
                  }}
                >
                  <Grid spacing="$md" css={{ padding: '$2xs' }}>
                    <Grid xl={5} css={{ display: 'flex', alignItems: 'center', gap: '$md' }}>
                      <Image
                        FallbackImage={() => (
                          <IconWrapper size="$2xl" css={{ backgroundColor: '$primary-container' }}>
                            <PersonIcon fill="$on-primary-container" />
                          </IconWrapper>
                        )}
                        alt="img"
                        css={{ borderRadius: '$full', height: 32, width: 32 }}
                        src={option.picture}
                      />
                      <Typography lineClamp={1} variant="bodyMedium" css={{ color: '$on-surface' }}>
                        {option.name}
                      </Typography>
                    </Grid>
                    <Grid xl={2} css={{ display: 'flex', alignItems: 'center' }}>
                      <Typography lineClamp={1} variant="bodyMedium" css={{ color: '$on-surface' }}>
                        {option.enrollmentDate}
                      </Typography>
                    </Grid>
                    <Grid xl={2} css={{ display: 'flex', alignItems: 'center', gap: '$2xs' }}>
                      <Switch
                        size="sm"
                        isLoading={
                          updateStudentConsent.isPending &&
                          Number(option.id) === updateStudentConsent.variables.model.studentId
                        }
                        inputProps={{
                          checked: option.allowedImageUseOnEdtech,
                          onChange: () =>
                            handleImageConsent(option.id, option.allowedImageUseOnEdtech)
                        }}
                      />
                      <Typography
                        lineClamp={1}
                        variant="labelMedium"
                        css={{ color: '$on-surface-variant' }}
                      >
                        {i18n().modules.hub.classroom.pages.enrollments.list.allows}
                      </Typography>
                    </Grid>
                  </Grid>
                </BaseCard>
              ))}
            </UL>
            {listClassroomStudents.data.lastPage > 1 && (
              <Pagination
                lastPage={listClassroomStudents.data.lastPage}
                page={listClassroomStudentsParams.page ?? 1}
                setPage={(page) =>
                  setListClassroomStudentsParams((oldState) => ({ ...oldState, page }))
                }
              />
            )}
          </Grid>
        )}
      </Div>
    </Main>
  )
}
